$(function() {
	new WOW().init();
	smoothScroll.init();
	
	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('.site-header').outerHeight();

	$(window).scroll(function(event){
	    didScroll = true;
	});

	setInterval(function() {
	    if (didScroll) {
	        hasScrolled();
	        didScroll = false;
	    }
	}, 250);

	function hasScrolled() {
	    var st = $(this).scrollTop();
	    
	    // Make sure they scroll more than delta
	    if(Math.abs(lastScrollTop - st) <= delta)
	        return;
	    
	    // If they scrolled down and are past the navbar, add class .nav-up.
	    // This is necessary so you never see what is "behind" the navbar.
	    if (st > lastScrollTop && st > navbarHeight){
	        // Scroll Down
	        $('.site-header').removeClass('nav-down').addClass('nav-up');
	    } else {
	        // Scroll Up
	        if(st + $(window).height() < $(document).height()) {
	            $('.site-header').removeClass('nav-up').addClass('nav-down');
	        }
	    }
	    
	    lastScrollTop = st;
	}

	$(window).on("scroll", function() {
	    if ($(this).scrollTop() > 200) {
			$('.site-header').addClass('scrolled');
	    }
	    else {
	    	$('.site-header').removeClass('scrolled');
	    }
	 });

	$('.main-navigation .nav-wrapper .menu-menu-1-container .nav-menu li a').on('click', function() {
		$('.main-navigation.toggled').removeClass('toggled');
		$('html').css('overflow-y', 'auto');
	});

	$('.menu-toggle').on('click', function() {
		if($('.main-navigation').hasClass('toggled')) {
			$('html').css('overflow-y', 'hidden');
		}else {
			$('html').css('overflow-y', 'auto');
		}
	});
});